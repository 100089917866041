import React from "react"	
import { graphql } from "gatsby"
import Layout from "../components/layout"	
import Product from "../components/product"	
import ProductNotFound from "../components/productNotFound"
import { useLocation } from '@reach/router';	

const getProduct = (products, scrapeUrl) => products.find(product => product.node.scrapeUrl === scrapeUrl);	

export default function Go(props) {	
  const location = useLocation();
  const products = props.data.allMongodbDevelopProducts.edges;
  const scrapeUrl = location.search ? location.search.split('?').pop() : undefined;	

  const scrapeUrlWithoutSlash = scrapeUrl ? scrapeUrl.replace(/\/$/, "") : scrapeUrl;
  const product = getProduct(products, scrapeUrlWithoutSlash);

  return (	
    <Layout blog={true}>	
      <div className="full-page-module">
        <Product product={product} />
      </div>
    </Layout>	
  )	
};

export const pageQuery = graphql`
  query{
    allMongodbDevelopProducts {
      edges {
        node {
          mongodb_id
          name
          scrapeUrl
          affiliateUrl
          retailer
          roles
          tags
          experiment
          priority
        }
      }
    }
  }
`